<template>
  <div class="table">
    <div class="head">
      <el-button type="success" @click="daochu">导出列表</el-button>
    </div>
    <div class="tablebox">
      <el-table
        :data="
          tableData
        "
        style="width: 100%"
        border
      >
        <el-table-column prop="recordDate" label="执法时间"> </el-table-column>
        <el-table-column prop="createTime" label="上传时间"> </el-table-column>
        <el-table-column prop="deptName" label="执法部门"> </el-table-column>
        <el-table-column prop="userName" label="执法人"> </el-table-column>
        <el-table-column prop="companyName" label="公司名称"> </el-table-column>
        <el-table-column prop="content" label="执法内容"> </el-table-column>
        <el-table-column prop="lawType" label="处罚类型"></el-table-column>
        <el-table-column label="操作" width="300px">
          <template slot-scope="scope">
            <div style="display: flex">
              <el-button
                type="primary"
                size="mini"
                @click="seedata(scope.row)"
                style="margin-right: 10px"
              >
                查看
              </el-button>
              <div>
                <el-button
                  v-if="scope.row.approvalResult !== '待提交'"
                  type="primary"
                  size="mini"
                  @click="handleUpdate(scope.row)"
                >
                  公开
                </el-button>
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.row)"
                  v-if="qx == '管理员'"
                >
                  不公开
                </el-button>
              </div>
              <div
                class="Yes"
                v-if="scope.row.approvalResult == '审批通过'"
                style="margin-left: 10px"
              >
                已通过
              </div>
              <div
                class="No"
                v-if="scope.row.approvalResult == '审核失败'"
                style="margin-left: 10px"
              >
                未通过
              </div>
              <div
                class="No"
                v-if="scope.row.approvalResult == '待提交'"
                style="margin-left: 10px"
              >
                待提交
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="pageNum"
          :page-sizes="[5, 10, 20, 40]"
          :page-size="pageSize"
          layout="sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      width="50%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <!-- 表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="80px"
        :model="formLabelAlign"
      >
        <!-- <el-form-item label="标题">
          <el-input v-model="formLabelAlign.title" :disabled="true"></el-input>
        </el-form-item> -->
        <el-form-item label="公司名称">
          <el-input
            v-model="formLabelAlign.companyName"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="执法内容">
          <el-input
            v-model="formLabelAlign.content"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="处罚类型">
          <el-input
            v-model="formLabelAlign.lawType"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <template>
            <viewer :images="formLabelAlign.imageUrl">
              <img
                :src="item"
                v-for="(item, index) in formLabelAlign.imageUrl"
                :key="index"
                alt=""
                style="width: 100px; height: 100px"
              />
            </viewer>
          </template>
        </el-form-item>
        <el-form-item label="视频">
          <div v-html="sp"></div>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetsysLawEnforcementRecordmyApprovalList,
  PostsysLawEnforcementRecordapproval,
  Getadmininfo,
  GetexportMyApprovalList,
} from "@/request/api";
export default {
  name: "TableApprovalAdmin",

  data() {
    return {
      input: "", //搜索内容
      tableData: [],
      dialogVisible: false, //弹出框判定
      labelPosition: "right",
      formLabelAlign: {
        //弹窗数据
        title: "",
        companyName: "",
        content: "",
        lawType: "",
        imageUrl: "",
        videoUrl: "",
      },
      checked: false,

      options: [],
      value: "",
      value2: "",
      sp: "",
      currentPage: 1, //分页
      pageNum: 1,
      pageSize: 10,
      total: 1,
      qx: "",
    };
  },

  methods: {
    handleUpdate(v) {
      this.$confirm("此操作将审核通过该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          PostsysLawEnforcementRecordapproval({
            approvalResult: "审批通过",
            id: v.id,
            rejectionReason: "",
            secondApproval: "",
          }).then((res) => {
            // console.log(res);
            if (res.code == 200) {
              this.seelist();
              this.$message({
                type: "success",
                message: "审核成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消审核",
          });
        });
    },
    // 导出
    daochu() {
      GetexportMyApprovalList().then((res) => {
        const url = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(url);
        a.download = "执法记录.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },
    // 查看
    seedata(v) {
      this.formLabelAlign.title = v.title;
      this.formLabelAlign.companyName = v.companyName;
      this.formLabelAlign.content = v.content;
      this.formLabelAlign.lawType = v.lawType;
      this.formLabelAlign.imageUrl = v.imageUrl.split(",");
      this.formLabelAlign.videoUrl = v.videoUrl;
      this.sp = `<video controls="controls" preload="auto" width="100%" height="500px" >
               <source src="${
                 this.formLabelAlign.videoUrl.split(",")[0]
               }" type="video/mp4" />
             </video>`;
      this.dialogVisible = true;
    },
    // 图片预览
    preview(url){
      // var viewer = new Viewer(document.getElementById(url), {
      //     url: item.publicFileURL,
      // })
    },
    // 审核拒绝
    handleDelete(v) {
      // console.log(v);
      this.$confirm("此操作将审核通过该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          PostsysLawEnforcementRecordapproval({
            approvalResult: "审核失败",
            id: v.id,
            rejectionReason: "",
            secondApproval: "",
          }).then((res) => {
            // console.log(res);
            if (res.code == 200) {
              this.seelist();
              this.$message({
                type: "success",
                message: "审核成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消审核",
          });
        });
    },
    //添加数据
    clickadd() {
      this.formLabelAlign.name = "";
      this.formLabelAlign.date = "";
      this.formLabelAlign.type = "";
      this.dialogVisible = true;
    },
    //分页
    handleSizeChange(val) {
      // console.log(val);
      this.pageSize = val;
      this.seelist();
    },
    handleCurrentChange(val) {
      // console.log(val);
      this.pageNum = val;
      this.seelist();
    },
    // 登录权限
    quanxian() {
      Getadmininfo().then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.qx = res.data.roles[0];
        }
      });
    },
    // 审批列表
    seelist() {
      GetsysLawEnforcementRecordmyApprovalList({
        approvalResult: "",
        companyName: "",
        enforceType: "",
        name: "",
        nickName: "",
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        rangDate: "",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.currentPage = res.totalPage;
          this.total = res.data.total;
        }
      });
    },
  },
  mounted() {
    this.quanxian();
    this.seelist();
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  .head {
    margin-bottom: 15px;
    display: flex;
  }
  .tablebox {
    width: 100%;
    padding: 10px;
    background-color: #fff;
  }
  .Yes {
    color: green;
  }
  .No {
    color: red;
  }
  .block {
    text-align: center;
    margin-top: 15px;
  }
}
</style>